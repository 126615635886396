<script>
  import QuantidadeTomadores from './DashboardCadastroComponents/QuantidadeTomadores.vue'
  import MediaRemuneracao from './DashboardCadastroComponents/MediaRemuneracao.vue'
  import TomadoresPorSexo from './DashboardCadastroComponents/TomadoresPorSexo.vue'
  import MediaMargemDisponivel from './DashboardCadastroComponents/MediaMargemDisponivel.vue'
  import MediaIdadePorSexo from './DashboardCadastroComponents/MediaIdadePorSexo.vue'
  import MediaMargemUtilizada from './DashboardCadastroComponents/MediaMargemUtilizada.vue'
  import MediaDoencaPorSexo from './DashboardCadastroComponents/MediaDoencaPorSexo.vue'
  import MediaTomadoresPorOrgao from './DashboardCadastroComponents/MediaTomadoresPorOrgao.vue'

  export default {
    components: {
      QuantidadeTomadores,
      MediaRemuneracao,
      TomadoresPorSexo,
      MediaMargemDisponivel,
      MediaIdadePorSexo,
      MediaMargemUtilizada,
      MediaTomadoresPorOrgao,
      MediaDoencaPorSexo,
    },
  }
</script>

<template>
  <div class="grid-cadastro">
    <QuantidadeTomadores style="grid-area: quantidade" />
    <TomadoresPorSexo style="grid-area: tomadoresporsexo" />
    <MediaIdadePorSexo style="grid-area: mediaidadeporsexo" />

    <MediaRemuneracao style="grid-area: mediaremuneracao" />
    <MediaMargemUtilizada style="grid-area: mediamargemutilizada" />
    <MediaMargemDisponivel style="grid-area: mediamargemdisponivel" />

    <MediaTomadoresPorOrgao style="grid-area: mediatomadorespororgao" />
    <MediaDoencaPorSexo style="grid-area: mediadoencaporsexo" />
  </div>
</template>

<style>
  .grid-cadastro {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 2fr 1fr;
    gap: 20px;
    grid-template-areas:
      'quantidade mediaremuneracao mediatomadorespororgao'
      'tomadoresporsexo mediamargemdisponivel mediatomadorespororgao'
      'mediaidadeporsexo mediamargemutilizada mediadoencaporsexo';
  }
</style>
