<script>
  import Ripple from 'vue-ripple-directive'
  import { formatarData } from '@/libs/utils'
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      formatarData,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        url: '',
        base: '',
      }
    },
    mounted() {
      this.getURL()
    },
    methods: {
      async getURL() {
        const institutoSelecionado = this.userData.institutoSelecionado

        useJwt.get(`integracoes/${institutoSelecionado}`).then((response) => {
          this.url = response.data.dashboardProposta
        })
      },
    },
  }
</script>

<template>
  <b-card style="overflow-x: auto">
    <!-- <iframe title="BI_CONSIGPREV_PROPOSTAS_V1 - PROPOSTA" id="BI_CONSIGPREV_PROPOSTAS_V1" src="https://app.powerbi.com/view?r=eyJrIjoiN2RlNjRlZmMtY2RkZC00N2M0LTk1M2ItYTZjZTE2ZDRkOWIwIiwidCI6ImUzMGIwMTUyLTQ4OWMtNDllYS05NGY2LWRiMmNlZDQ0ZDBmZCJ9" frameborder="0" allowFullScreen="true"></iframe> -->
    <iframe
      title="BI_CONSIGPREV - PROPOSTA"
      id="BI_CONSIGPREV_PROPOSTAS_V1"
      :src="url"
      frameborder="0"
      allowFullScreen="true"
    ></iframe>
  </b-card>
</template>

<style>
  iframe#BI_CONSIGPREV_PROPOSTAS_V1 {
    width: 100%;
    height: 1020px;
  }

  iframe#BI_CONSIGPREV_PROPOSTAS_V1 div.unselecttable {
    display: none !important;
  }
</style>
