<template>
  <b-card class="tw-h-full tw-flex tw-items-center tw-justify-center rounded-lg">
    <b-card-body class="tw-flex tw-flex-col tw-items-center">
      <b-card-title> Tomadores x Sexo </b-card-title>
      <b-skeleton v-if="isBusy" type="Avatar" />
      <vue-apex-charts v-else height="300" :series="tomadoresDataArr" :options="earningsChart.chartOptions" />
    </b-card-body>
  </b-card>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts'
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarToStringComPonto } from '@/libs/utils.js'

  export default {
    components: {
      VueApexCharts,
    },
    mounted() {
      this.carregarParams()
    },
    data() {
      return {
        isBusy: false,
        tomadoresData: {},
        tomadoresDataArr: [],
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        earningsChart: {
          chartOptions: {
            chart: {
              type: 'donut',
              toolbar: {
                show: false,
              },
            },

            dataLabels: {
              enabled: false,
              padding: 100,
              textAnchor: 'end',
              style: {
                fontSize: '20x',
                colors: ['#8077CC'],
              },
              background: {
                enabled: true,
                foreColor: ['#FFFFFF'],
                borderRadius: 8,
                padding: 10,
              },
              formatter: function (val, opts) {
                return opts.w.config.labels[opts.seriesIndex] + ': ' + opts.w.config.series[opts.seriesIndex]
              },
            },
            legend: {
              show: true,
              position: 'bottom',
              fontSize: '16px',
              formatter: function (val, opts) {
                return (
                  opts.w.config.labels[opts.seriesIndex] +
                  ': ' +
                  formatarToStringComPonto(opts.w.config.series[opts.seriesIndex])
                )
              },
            },
            labels: ['Feminino', 'Masculino'],
            fontSize: '10px',
            colors: ['#be8dd2', '#8272bd'],
            grid: {
              padding: {
                right: -20,
                bottom: -8,
                left: -20,
              },
            },
            plotOptions: {
              pie: {
                startAngle: -10,
                donut: {
                  labels: {
                    show: true,
                    fontSize: '10px',
                    name: {
                      offsetY: -10, // Ajuste para evitar sobreposição
                    },
                    value: {
                      offsetY: 10, // Ajuste para evitar sobreposição
                    },
                    total: {
                      show: true,
                      offsetY: 15,
                      label: 'Total',
                    },
                  },
                },
              },
            },
            responsive: [
              {
                breakpoint: 1325,
                options: {
                  chart: {
                    height: 100,
                  },
                },
              },
              {
                breakpoint: 1200,
                options: {
                  chart: {
                    height: 90,
                  },
                },
              },
              {
                breakpoint: 1045,
                options: {
                  chart: {
                    height: 70,
                  },
                },
              },
              {
                breakpoint: 992,
                options: {
                  chart: {
                    height: 120,
                  },
                },
              },
            ],
          },
        },
      }
    },
    methods: {
      formatarToStringComPonto,
      carregarParams() {
        this.isBusy = true
        useJwt
          .get(`cadastro/tomador/getQuantidadeServidoresPorSexo/${this.userData.Instituto.id}`)
          .then((res) => {
            this.tomadoresData = res.data
            this.tomadoresDataArr = [this.tomadoresData[0].qtd, this.tomadoresData[1].qtd]
          })
          .finally(() => {
            this.isBusy = false
          })
      },
    },
  }
</script>
