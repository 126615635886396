<template>
  <b-card no-body class="tw-h-full tw-bg-slate-400">
    <b-card-body class="tw-flex tw-flex-col tw-items-center">
      <b-card-title class="tw-w-full tw-text-center">Portador Doença Incapacitante x Sexo</b-card-title>
      <b-skeleton v-if="isBusy" type="Avatar" />
      <p v-if="!isBusy && itsEmpty">Nenhum resultado</p>
      <vue-apex-charts v-if="!isBusy && !itsEmpty" type="bar" height="200" :options="options" :series="series" />
    </b-card-body>
  </b-card>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts'
  import useJwt from '@/auth/jwt/useJwt'
  export default {
    components: {
      VueApexCharts,
    },
    data() {
      return {
        series: [],
        isBusy: false,
        itsEmpty: false,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        options: {
          chart: {
            type: 'bar',
            height: 250,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              borderRadius: 210, // Define o raio da borda
              barHeight: '50%', // Ajusta a altura da barra para parecer mais arredondada,

              dataLabels: {
                position: 'top',
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
              return val
            },
            style: {
              colors: ['#644796'],
              fontSize: '20px',
            },
          },
          xaxis: {
            categories: [],
            labels: {
              show: false,
            },
          },
          yaxis: {
            show: true,
            labels: {
              style: {
                colors: ['#6C757D'],
                fontSize: '14px',
              },
            },
          },
          colors: ['#be8dd2', '#573b92'],
          tooltip: {
            enabled: true,
          },
          legend: {
            show: false,
          },
          grid: {
            show: false,
          },
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 420,
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                    borderRadius: 200, // Define o raio da borda para visualizações menores
                  },
                },
              },
            },
          ],
        },
      }
    },
    mounted() {
      this.carregarParams()
    },
    methods: {
      carregarParams() {
        this.isBusy = true
        useJwt
          .get(`cadastro/tomador/getQuantidadePortadorDoencaPorSexo/${this.userData.Instituto.id}`)
          .then((res) => {
            this.options.xaxis.categories = res.data.map((tomador) => tomador.sexo)
            this.series = [
              {
                name: 'Quantidade',
                data: res.data.map((tomador) => tomador.qtd),
              },
            ]

            if (this.series[0].data.every((val) => val === 0)) this.itsEmpty = true
          })
          .finally(() => {
            this.isBusy = false
          })
      },
    },
  }
</script>

<style scoped>
  /* Adicione o estilo necessário aqui */
</style>
