<template>
  <b-card no-body class="tw-h-full">
    <b-card-header>
      <b-card-title class="tw-w-full tw-text-center">Tomadores x Orgão</b-card-title>
    </b-card-header>

    <b-card-body>
      <b-skeleton v-if="isBusy" type="Avatar" />
      <vue-apex-charts
        v-if="!isBusy && !itsEmpty"
        type="bar"
        :height="600"
        :width="chartWidth"
        :options="options"
        :series="series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts'
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarToStringComPonto } from '@/libs/utils.js'

  export default {
    components: {
      VueApexCharts,
    },
    data() {
      return {
        series: [],
        isBusy: false,
        itsEmpty: false,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        chartWidth: '100%', // Define the default chart width
        options: {
          chart: {
            type: 'bar',
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 10,
              barHeight: '50%',
              dataLabels: {
                position: 'top',
              },
            },
          },
          dataLabels: {
            enabled: true,
            style: {
              colors: ['#573b92'],
              fontSize: '16px',
            },
            offsetY: -40,
            formatter: function (value) {
              return formatarToStringComPonto(value)
            },
          },
          xaxis: {
            categories: [],
            labels: {
              show: true,
              style: {
                colors: ['#6C757D'],
                fontSize: '10px',
              },
              formatter: function (value) {
                if (value) {
                  return String(value).split(' ')[0]
                }
                return ''
              },
            },
          },
          yaxis: {
            show: false,
          },
          colors: ['#573b92'],
          tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
              const category = w.globals.labels[dataPointIndex]
              const value = series[seriesIndex][dataPointIndex]
              return `<div class="apexcharts-tooltip-title">${category}</div><div class="apexcharts-tooltip-value">${value}</div>`
            },
          },
          legend: {
            show: false,
          },
          grid: {
            show: false,
          },
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 420,
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                    borderRadius: 2, // Corrige o raio da borda para visualizações menores
                  },
                },
              },
            },
          ],
        },
      }
    },
    mounted() {
      this.carregarParams()
      this.adjustChartWidth() // Adjust chart width on mount
      window.addEventListener('resize', this.adjustChartWidth) // Adjust chart width on window resize
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.adjustChartWidth)
    },
    methods: {
      formatarToStringComPonto,
      carregarParams() {
        this.isBusy = true
        useJwt
          .get(`cadastro/tomador/getQuantidadeTomadoresPorOrgao/${this.userData.Instituto.id}`)
          .then((res) => {
            const sortedData = res.data.sort((a, b) => b.qtdTomador - a.qtdTomador)
            this.options.xaxis.categories = sortedData.map((orgaos) => orgaos.orgao)
            this.series = [
              {
                name: 'Quantidade',
                data: sortedData.map((orgaos) => orgaos.qtdTomador),
              },
            ]

            const data = this.series[0].data
            // Ajuste de valores máximos e mínimos pode ser implementado de forma diferente se necessário
          })
          .finally(() => {
            this.isBusy = false
            this.itsEmpty = this.series[0].data.length === 0
          })
      },
      adjustChartWidth() {
        const containerWidth = this.$el.offsetWidth
        this.chartWidth = containerWidth < 576 ? '100%' : '100%' // Ajuste para 100% para ocupar a largura total do container
      },
    },
  }
</script>

<style scoped>
  .b-card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Certifica que o container do gráfico ocupa 100% da largura */
  }
</style>
