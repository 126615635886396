<template>
  <b-card no-body class="tw-h-full p-2">
    <h4 class="tw-w-full tw-text-center">Média Margem Utilizada x Categoria</h4>

    <b-card-body class="tw-flex tw-flex-col tw-justify-center">
      <b-row class="tw-absolute tw-z-10 tw-flex tw-justify-evenly tw-w-[95%]">
        <div class="tw-flex">
          <p class="p-1 tw-bg-[#E5BFAA] tw-rounded-full tw-text-white">
            <feather-icon size="24" icon="DollarSignIcon" />
          </p>

          <div class="tw-ml-2">
            <h4 class="font-weight-bolder mb-0">
              {{ mediaMargemDisponivel.mediaAtivo ? formatarValorReais(mediaMargemDisponivel.mediaAtivo) : 'R$ 0,00' }}
            </h4>
            <b-card-text class="font-small-3 mb-0"> Ativos </b-card-text>
          </div>
        </div>

        <div class="tw-flex">
          <p class="p-1 tw-bg-[#E5BFAA] tw-rounded-full tw-text-white">
            <feather-icon size="24" icon="DollarSignIcon" />
          </p>

          <div class="tw-ml-2">
            <h4 class="font-weight-bolder mb-0">
              {{
                mediaMargemDisponivel.mediaAposentado ? formatarValorReais(mediaMargemDisponivel.mediaAposentado) : 'R$ 0,00'
              }}
            </h4>
            <b-card-text class="font-small-3 mb-0">Aposentados </b-card-text>
          </div>
        </div>

        <div class="tw-flex">
          <p class="p-1 tw-bg-[#E5BFAA] tw-rounded-full tw-text-white">
            <feather-icon size="24" icon="DollarSignIcon" />
          </p>
          <div class="tw-ml-2">
            <h4 class="font-weight-bolder mb-0">
              {{
                mediaMargemDisponivel.mediaPensionista ? formatarValorReais(mediaMargemDisponivel.mediaPensionista) : 'R$ 0,00'
              }}
            </h4>
            <b-card-text class="font-small-3 mb-0"> Pensionistas </b-card-text>
          </div>
        </div>
      </b-row>
    </b-card-body>
    <img src="@/assets/images/illustration//WaveIcon-03.svg" class="background-image tw-select-none" />
  </b-card>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarValorReais } from '@/libs/utils'
  export default {
    components: {
      formatarValorReais,
    },
    data() {
      return {
        mediaMargemDisponivel: {
          mediaAtivo: 0,
          mediaPensionista: 0,
          mediaAposentado: 0,
        },
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        formatarValorReais,
      }
    },
    async mounted() {
      await useJwt
        .post(`cadastro/tomador/getMediaServidoresPorInstituto/${this.userData.Instituto.id}`, { type: 3 })
        .then((res) => {
          this.mediaMargemDisponivel = res.data
        })
    },
  }
</script>

<style scoped>
  .background-image {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Mantém a proporção da imagem */
    z-index: 1;
  }
</style>
