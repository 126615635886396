<template>
  <b-card no-body class="tw-h-full tw-flex tw-items-center tw-justify-center tw-bg-white rounded-lg">
    <b-card-body
      class="tw-text-[#6f4e98] text-center tw-rounded-lg px-16 tw-flex tw-flex-col tw-items-center pt-3 tw-justify-center"
    >
      <p class="p-1 tw-bg-[#352b8a]/20 tw-rounded-full tw-select-none">
        <feather-icon size="21" icon="UsersIcon" />
      </p>
      <div class="truncate">
        <h2 class="font-weight-bolder tw-text-[#6f4e98]">
          {{ formatarToStringComPonto(quantidadeTomador) }}
        </h2>
        <span class="tw-text-[#6f4e98] tw-font-bold tw-text-lg">Tomadores</span>
      </div>
    </b-card-body>
    <!-- <img src="@/assets/images/illustration/UserIcon.svg" class="background-image tw-select-none" /> -->
  </b-card>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarToStringComPonto } from '@/libs/utils.js'

  export default {
    components: {},
    data() {
      return {
        quantidadeTomador: 0,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
      }
    },
    async mounted() {
      await useJwt.get(`cadastro/tomador/getAllTomadoresPorInstituto/${this.userData.Instituto.id}`).then((res) => {
        this.quantidadeTomador = res.data.count
      })
    },
    methods: {
      formatarToStringComPonto,
    },
  }
</script>

<style scoped>
  .background-image {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 25%; /* 1/4 da largura do quadrado */
    height: auto;
    opacity: 0.2; /* Baixa opacidade */
    z-index: 1; /* Atrás do texto */
  }
</style>
