<template>
  <b-card no-body class="tw-h-full">
    <b-card-header>
      <b-card-title class="tw-w-full tw-text-center"> Média idade x Sexo</b-card-title>
    </b-card-header>

    <b-card-body>
      <b-skeleton v-if="isBusy" type="Avatar" />
      <vue-apex-charts v-if="!isBusy && !itsEmpty" type="bar" height="200" :options="options" :series="series" />
    </b-card-body>
  </b-card>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts'
  import useJwt from '@/auth/jwt/useJwt'

  export default {
    components: {
      VueApexCharts,
    },
    data() {
      return {
        series: [],
        isBusy: false,
        itsEmpty: false,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        options: {
          chart: {
            type: 'bar',
            height: 250,
            toolbar: {
              show: false,
            },
          },
          plotOptions: {
            bar: {
              horizontal: true,
              borderRadius: 210,
              barHeight: '50%',

              dataLabels: {
                position: 'top',
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
              return val
            },
            style: {
              colors: ['#ffffff'],
              fontSize: '14px',
            },
            offsetX: -30,
          },
          xaxis: {
            categories: ['Feminino', 'Masculino'],
            labels: {
              show: false,
            },
          },
          yaxis: {
            show: true,
            labels: {
              style: {
                colors: ['#6C757D'],
                fontSize: '14px',
              },
            },
          },
          tooltip: {
            enabled: true,
          },
          legend: {
            show: false,
          },
          grid: {
            show: false,
          },
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 420,
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                    borderRadius: 200, // Define o raio da borda para visualizações menores
                  },
                },
              },
            },
          ],
        },
      }
    },
    mounted() {
      this.carregarParams()
    },
    methods: {
      carregarParams() {
        this.isBusy = true
        useJwt
          .get(`cadastro/tomador/getMediaIdadePorSexo/${this.userData.Instituto.id}`)
          .then((res) => {
            const categories = ['Feminino', 'Masculino']
            const data = res.data.map((tomador, index) => ({
              x: categories[index],
              y: tomador.mediaIdade,
              fillColor: index === 0 ? '#be8dd2' : '#be8dd2',
            }))

            this.series = [
              {
                name: 'Quantidade',
                data: data,
              },
            ]

            this.options = {
              ...this.options,
              xaxis: {
                ...this.options.xaxis,
                categories: categories,
              },
            }

            if (data.every((val) => val.y === 0)) this.itsEmpty = true
          })
          .finally(() => {
            this.isBusy = false
          })
      },
    },
  }
</script>
